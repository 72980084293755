import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import Layout from 'Modules/Layout/FullWidth';
import './welcome.scss';

import Link from 'Utilities/Link';

import Button from 'Components/Button/Button';

import CurrentUserService from 'Services/CurrentUser';
import AppStateService,{ApplicationState,ApplicationMode} from 'Services/ApplicationState';
import Application,{IApplicationInput} from 'Services/Models/Application';

import l from 'lang';

interface Attr{

}


interface State{
    existingApplication : IApplicationInput;
    currentUser : { firstname: string, lastname : string};
    appState :ApplicationState;
    box : { title : any, content : any, action : any, style : any}
    timeZoneStr : string;
}

class Welcome extends Component<State,Attr>{

    static oninit(this: State, vnode : Vnode<State,Attr>){

        try {
            var zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (zoneName == undefined ) throw Error;
            this.timeZoneStr = zoneName;
        } catch{
            this.timeZoneStr = "offset " + (new Date()).getTimezoneOffset();
        }


        
        CurrentUserService.getUser().then(user => this.currentUser = user);
        
        
        CurrentUserService.getLastApplication()
            .then( app => this.existingApplication = app)
            .then( () =>
                AppStateService.get()
                    .then( appstate => { 

                        var boxTitle;
                        var boxContent;
                        var boxAction;
                        var boxStyle = "";
                        
                        if (appstate.mode == "INITIAL") {
                                boxTitle = "The application period has not started yet."
                                boxStyle = "Welcome__statusBox--red"
                                boxContent = "Please consult the Wiki pages of the School of Engineering & Design / Architecture for more information."
                        } else if (appstate.mode == "OPEN" && (new Date(appstate.closeOpenAt) > new Date()) && !this.existingApplication) {
                                boxTitle = "Get started!"
                                boxContent = <span>You can submit your application until <strong><time datetime={appstate.closeOpenAt} title={appstate.closeOpenAt}>{(new Date(appstate.closeOpenAt)).toLocaleString() } {this.timeZoneStr}</time></strong>. The application form will automatically close afterwards.</span>;
                                boxAction  = <Button primary href="/browse" oncreate={Link}>{l("welcome.create_new_application")}</Button>
                        } else if (appstate.mode == "VALIDATION"  || (appstate.mode == "OPEN" && (new Date(appstate.closeOpenAt) < new Date())) && !this.existingApplication) {
                            boxTitle = "Applications are closed"
                            boxStyle = "Welcome__statusBox--red"
                            boxContent = "Please consult the Wiki pages of the School of Engineering & Design / Architecture for more information."
                        } else if (this.existingApplication && this.existingApplication.state == "ASSIGNED") {
                            boxTitle = "Application Successfull"
                            boxStyle = "Welcome__statusBox--green"
                            boxContent = <span>We are happy to announce that you have been nominated for an exchange place abroad via the faculty’s exchange program TUM SOMex (incl. Erasmus+). <strong>Action required:</strong> Please accept or decline your nomination</span>
                            if ( appstate.mode == "PUBLISH" &&(new Date(appstate.closeAcceptAt) > new Date())) {
                                boxAction = <Button primary href={"/accept/" + this.existingApplication.id} oncreate={Link}>Open Acceptance Form</Button>
                            }
                        } else if (this.existingApplication && this.existingApplication.state == "ACCEPTED") {
                            boxTitle = "You have accepted your assignment"
                            boxStyle = "Welcome__statusBox--green"
                            boxContent = "From March on, all nominees of one destination will receive bit by bit further information. Please read all information concerning your stay abroad carefully. From April on, the application processes at the partner universities will start."
                            if ( appstate.mode == "PUBLISH" &&(new Date(appstate.closeAcceptAt) > new Date())) {
                                boxAction = <Button  href={"/accept/" + this.existingApplication.id} oncreate={Link}>Change Reply</Button>
                            }
                        } else if (this.existingApplication && this.existingApplication.state == "DECLINED") {
                                boxTitle = "You have declined your assignment"
                                boxContent = "Your assignment has been declined."
                                boxStyle = "Welcome__statusBox--red"
                                if (appstate.mode == "PUBLISH" &&(new Date(appstate.closeAcceptAt) > new Date())) {
                                    boxAction = <Button primary href={"/accept/" + this.existingApplication.id} oncreate={Link}>Open Acceptance</Button>
                                }
                        } else if (this.existingApplication && this.existingApplication.state == "NOT_ASSIGNED") {
                                boxTitle = "Unfortunately, your application could not assigned to an exchange spot."
                                boxContent = "Your assignment has been declined."
                                boxStyle = "Welcome__statusBox--red"
                        }  else if (this.existingApplication) {
                            boxTitle = "Application Received"
                            boxContent = "We have received your application and will update you once we have processed it"
                            boxStyle = "Welcome__statusBox--green"
                            boxAction = <div>
                                     <Button primary href={"/preview/" + this.existingApplication.id} oncreate={Link}>View Application</Button>
                                      { appstate.mode == "OPEN" && (new Date(appstate.closeOpenAt) > new Date()) ? <Button href={"/browse/"} oncreate={Link}>Replace Application</Button> : "" }
                                </div>
                        } else {
                            boxTitle = "Applications are closed"
                            boxStyle = "Welcome__statusBox--red"
                            boxContent = "Please consult the Wiki pages of the School of Engineering & Design / Architecture for more information."
                        }

                        this.box = { title : boxTitle , content : boxContent, action : boxAction, style : boxStyle};

                        this.appState = appstate
                
                    }));
    }
    static view(this: State, vnode : Vnode<State,Attr>){
        return <Layout className="Welcome" subTitle={l("welcome.title", {name : this.currentUser ? (this.currentUser.firstname + " "+ this.currentUser.lastname) : ""})} title="Start" >
            
            {l("welcome.intro_text").split("\n").map(line => <p>{line}</p>)}

            <div className="Welcome__boxRow">

                <div className="Welcome__statusBox_container">

                 {this.appState && this.box ? <div>
                   
                   <div className={classnames("Welcome__statusBox", this.box.style)}>
                        <strong className="Welcome__statusBox_header">{this.box.title}</strong>
                        <p className="Welcome__statusBox_content">{this.box.content}</p>
                        {this.box.action ? <div className="Welcome__statusBox_actionBar">
                           {this.box.action}
                        </div> : ""}
                    </div>
                    </div> : <div className="Welcome__statusBox shimmer">
                        <strong className="Welcome__statusBox_header shimmer"></strong>
                        <p className="Welcome__statusBox_content shimmer"></p>
                    </div> }
                </div>
                
            </div>
            {this.currentUser && (this.currentUser as any).role && (this.currentUser as any).role.indexOf("administration")>-1 
                ? <Button oncreate={Link} href="/admin" >Administration</Button> : ""}
        
        </Layout>
    }
}

export default Welcome;
