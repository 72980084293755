import * as m from 'mithril';

import Country from './Models/Country';
import WebService from './Classes/WebService';
import Collection from './Classes/Collection';

export class Countries extends WebService<Collection<Country>,Country>{

    constructor(){
        super( "/countries", Collection,Country);
    }

    mapIdToString(id : number) : string{
       return this.model(id) ? this.model(id).name() : "";
    }
}

var instance = new Countries();
export default instance;
