import * as m from 'mithril';
import stream from 'mithril/stream';
import {Component, Vnode} from 'Components/Component';
import * as classnames from 'classnames';
import './contingentSelector.scss';

import {getNativeProps,buttonProperties} from 'office-ui-fabric-react/lib/utilities/properties'

import TextField from 'Components/TextField/TextField';


import ContingentService from 'Services/Contingents'
import Contingent from 'Services/Models/Contingent';

import ShortlistService from 'Services/Shortlist';
import CurrentUserService from 'Services/CurrentUser';

import GlobalDialog from 'Utilities/GlobalDialog';
import AppStateService from 'Services/ApplicationState';

import CountriesService from 'Services/Countries'
import Country from 'Services/Models/Country';

import UniversityService from 'Services/Universities'
import University from 'Services/Models/University';

import ProgramService from 'Services/Programs'
import Program from 'Services/Models/Program';


import l from 'lang';
import Button from 'Components/Button/Button';
import ProgramItem from 'Modules/Browse/ProgramItem/ProgramItem';

interface Attr {
    onselect : (contingent:string | null) => void;
}


type contExt = Contingent & {program : Program, university : University, country : Country};

interface State {
    filteredItems? : prop<contExt[]>;
    searchBox : prop<string>
}

export default class ContingentSelector extends Component<State, Attr>{
    static oninit(vnode: Vnode<State, Attr>) {
        vnode.state.searchBox = stream("");

        let contingentStream = ContingentService.collection().stream();
        let countryStream = CountriesService.collection().stream();
        let universityStream = UniversityService.collection().stream();
        let programStream = ProgramService.collection().stream();

        ContingentService.collection().fetch(false);
        CountriesService.collection().fetch(false);
        ShortlistService.collection().fetch(false);
        UniversityService.collection().fetch(false);
        ProgramService.collection().fetch(false);

        

        let updateFilter = () => {

            type contExt = Contingent & {program : Program, university : University, country : Country};
            let filtered  : contExt[] = contingentStream();

            if (vnode.state.searchBox().length == 0) {
                filtered = []
            }
           
            // lookup program and university information from services
            filtered = filtered.map( p => {
                  let program = ProgramService.model(p.programId());
                  let university = UniversityService.model(program.universityId());
                  let country = CountriesService.model(university.countryId());
                  p.program = program;
                  p.university = university;
                  p.country = country;

                  return p;
            });

            
            

            // Text Search
            
            let searchText = (vnode.state.searchBox() as string).toLowerCase();
        
            filtered = filtered.filter( p => {
                
                

                let matchProgram = p.program.name().toLowerCase().search(searchText) !== -1;
                let matchUniversity = p.university.name().toString().toLowerCase().search(searchText) !== -1;
                let matchCountry = p.country.name().toString().toLowerCase().search(searchText) !== -1;

                return matchProgram || matchUniversity || matchCountry;
            });
            

            window.setTimeout(m.redraw,1); 
            return filtered;


        };

        vnode.state.filteredItems = stream.combine(updateFilter, 
            [countryStream, contingentStream, universityStream, programStream ,vnode.state.searchBox]);

    }

   

    static view(vnode: Vnode<State, Attr>) {

            console.log(vnode.attrs.onselect);
            return <div className="ContingentSelector">
                <h1>Editing assigned contingent</h1>

            <TextField label="Please use the search box to find the contingent. You can search over countries, universities and programs" bind={vnode.state.searchBox} placeholder="Search..." autoFocus ></TextField>
            
            <ul className="ContingentSelector_List">{
                (vnode.state.filteredItems ? vnode.state.filteredItems() || [] : []).slice(0,10).map(e => <li aria-role="button" onclick={vnode.attrs.onselect.bind(this, e.id)}>
                    <strong>{e.country.name()} • {e.university.name()} • {e.program.name()}</strong>
                            <div>
                                { e.levelRestriction() } / {  ProgramItem.renderSemesterAvailability(e.semesterAvailability()) }
                                <p>{ e.languageRequirementList()}</p>
                            </div>


                    </li>)
                }
            </ul>
            <Button onclick={vnode.attrs.onselect.bind(this, null)}>Remove Assignment</Button>
            <Button onclick={vnode.attrs.onselect.bind(this, false)}>Cancel</Button>
            </div>
    }
}
