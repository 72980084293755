import * as m from 'mithril';

import GlobalDialog from 'Utilities/GlobalDialog';

export enum UploadStatus {
    Added,
    Uploading,
    Cancelled,
    Error,
    Finished
  }
  
export class UploaderFile {
  
  
  
    public id: string;
    public name : string;
  
    public file?: File;
  
    public uploadUrl : string;
    public xhr: XMLHttpRequest;
  
    public status: UploadStatus = UploadStatus.Added;
  
    public loaded: number;
    public total: number;
    public percentUploaded: number;
  
    public handleError(e: ErrorEvent) {
      this.status = UploadStatus.Error;
      if (e.message && e.message.indexOf("413 Request Entity Too Large")!=-1) {
          GlobalDialog.error("File too large","The file you are trying to upload is too large. Please try again with a smaller file.","Ok")
      } else {
          GlobalDialog.error("File upload failed", "We could not upload your file. Please ensure to only upload PDF files of reasonable size.", "Ok");
      }
      m.redraw();
    }
  
    public handleProgress(e: ProgressEvent) {
      this.status = UploadStatus.Uploading;
      if (e.lengthComputable) {
        this.loaded = e.loaded;
        this.total = e.total;
        this.percentUploaded = Math.floor(e.loaded / e.total * 100);
      }
      m.redraw();
    }
  }