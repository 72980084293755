import * as m from 'mithril';

import University from './Models/University';
import WebService from './Classes/WebService';
import Collection from './Classes/Collection';

export class Universities extends WebService<Collection<University>,University>{

    constructor(){
        super( "/universities", Collection,University);
    }

    mapIdToString(id : number) : string{
       return this.model(id) ? this.model(id).name() : "";
    }
}

var instance = new Universities();
export default instance;
