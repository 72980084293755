import Model from '../Classes/Model';
import Service from '../Classes/Service';
import Stream from 'mithril/stream';

import {SEMESTER_RESTRICTION,STUDENT_LEVEL_RESTRICTION} from 'Services/Constants';
import { SemesterSelectionBitmask } from './AppForm';

export interface IContingentInput{
    programId: string | number;
    places : number;
    semesterAvailability : SemesterSelectionBitmask;
    levelRestriction : STUDENT_LEVEL_RESTRICTION;
    languageRequirementList : string;
    gpaRequirement : number;
    notes : string;


    useCountInsteadOfSCU : boolean;
    evenSemesterSplit : boolean;
}
export interface IContingent{
    
    programId: prop<string | number>;
    places : prop<number>;
    semesterAvailability : prop<SemesterSelectionBitmask>;
    levelRestriction : prop<STUDENT_LEVEL_RESTRICTION>;
    languageRequirementList : prop<string>;
    gpaRequirement : prop<number>;
    notes : prop<string>;

    useCountInsteadOfSCU : prop<boolean>;
    evenSemesterSplit : prop<boolean>;
}


export class Contingent extends Model<IContingentInput> implements IContingent{
    programId: prop<string | number> = Stream();
    places : prop<number> = Stream();
    semesterAvailability : prop<SemesterSelectionBitmask> = Stream();
    levelRestriction : prop<STUDENT_LEVEL_RESTRICTION> = Stream();
    languageRequirementList : prop<string> = Stream();
    gpaRequirement : prop<number> = Stream();
    notes : prop<string> = Stream();


    useCountInsteadOfSCU : prop<boolean> = Stream();
    evenSemesterSplit : prop<boolean> = Stream();


    constructor(id : number | string, service : Service<any,any>){
        super(id,service);
        this.fix();
    }
}

export default Contingent;
