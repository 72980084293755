

// Konami Code Trigger
const LEFT = 37;
const UP = 38;
const RIGHT = 39;
const DOWN = 40;
const A = 65;
const B = 66;


// a variable to remember the 'position' the user has reached so far.
var konamiCodePosition = 0;

// add keydown event listener
document.addEventListener('keydown', function (e) {
    // get the value of the key code from the key map
    let key = e.keyCode;
    // get the value of the required key from the konami code
    let requiredKey = [UP, UP, DOWN, DOWN, LEFT, RIGHT, LEFT, RIGHT, B, A][konamiCodePosition];

    // compare the key with the required key
    if (key == requiredKey) {

        // move to the next key in the konami code sequence
        konamiCodePosition++;

        // if the last key is reached, activate konami
        if (konamiCodePosition == 10 /* length of konami code array*/) {

            // lazy payload loding
            import('./EasterEggPayload').then(m => m.default());
            konamiCodePosition = 0;
        }
    } else {
        konamiCodePosition = 0;
    }
});
