import * as m from 'mithril';
import stream from 'mithril/stream'
import {Component, Vnode} from 'Components/Component';
import classnames from 'classnames';

import Dropdown from '../Dropdown/Dropdown'

import "./languageSelector.scss";
import {getNativeProps,buttonProperties} from 'office-ui-fabric-react/lib/utilities/properties'

interface Attr {
    languages : prop<string>
    requiredLanguages : prop<string[]>;
}

interface State {
    levelMap :  {[key:string] : prop<any>}
    isValid : boolean;
}

export const selectorLanguages = {
        "DE" : "German",
        "EN" : "English",
        "FR" : "French",
        "IT" : "Italian",
        "NL" : "Dutch",
        "PT" : "Portuguese",
        "ES" : "Spanish",
        "ZH" : "Chinese",
        "HR" : "Croatian",
        "HE" : "Hebrew",
        "SL" : "Slovenian",
}

export const selectorLevels = {
    0 : "None",
    1 : "A1 – Breakthrough",
    2 : "A2 – Waystage",
    3 : "B1 – Threshold",
    4 : "B2 – Vantage",
    5 : "C1 – Effective Operational Proficiency",
    6 : "C2 – Mastery"
}

const levelSelect = Object.keys(selectorLevels).map( k => ({ key : k, value : selectorLevels[k]}))

/*
{
    "DE" : 1,
}
*/

function str_to_dict(str : string) {
    let empty = {}
    Object.keys(selectorLanguages).map( k => empty[k] = 0)

    return (str||"").split(";").reduce((a,e) => {
        let v = e.split(":"); 
        if(v[1] !== undefined) {
            let val = parseInt(v[1],10);
            if (!a[v[0]] || a[v[0]] < val){
                a[v[0]] = val;
            }
        }
        return a; }
        ,empty)
}

function dict_to_str(dic)  {

    let val : string[] = [];
    Object.keys(dic || {}).forEach(k => {
        val.push(k+":"+dic[k])
    })
    return val.join(";");
}

export default class languageSelector extends Component<State, Attr>{
    static oninit(vnode: Vnode<State, Attr>) {
       
        let lmap = {}
        Object.keys(selectorLanguages).forEach( k => {
            lmap[k] = stream({ key : 0, value : selectorLevels[0]})
        });

        vnode.attrs.languages.isInvalid = (val) => {
            console.log("isValid",val,vnode.state.isValid);
            if (!vnode.state.isValid) {
                return "Language Error"
            } else {
                return false;
            }
        };
       
        vnode.state.levelMap = lmap;



        vnode.attrs.languages.map( dic => {
            let get_val =  str_to_dict(dic);
            Object.keys(get_val).forEach(k => {

                let cur_val = lmap[k]();
                if(cur_val.key != get_val[k]) {
                    lmap[k]({ key : get_val[k], value : selectorLevels[get_val[k]]})
                }
            })
        })
        
                    
        stream.merge( [...Object.values(vnode.state.levelMap),vnode.attrs.requiredLanguages]).map( (maps_all: {key:string}[]) => {

                    let maps = maps_all.slice(0,maps_all.length - 1);
                    console.log("MAP",maps);
                    let keys = Object.keys(vnode.state.levelMap);
                    let out = {}
                    maps.slice(0,).forEach( (v,i) => {
                        out[keys[i]]  = parseInt(v.key,10)
                    })

                    console.log("REQ",vnode.attrs.requiredLanguages());
                    let requiredLanguages = str_to_dict((vnode.attrs.requiredLanguages()||[]).join(";"));
                    console.log("dict", requiredLanguages);
                    vnode.state.isValid = ! maps.some( (v,i) =>  {
                        return (Object.values(requiredLanguages) as number[])[i] > parseInt(v.key,10)
                    }
                    );

                    console.log("SET",vnode.state.isValid);

                
                    vnode.attrs.languages(dict_to_str(out));

        });
    }

   

    static view(vnode: Vnode<State, Attr>) {

        let origClassName = (vnode.attrs as any).className || "";
        
        let requiredLanguages = str_to_dict((vnode.attrs.requiredLanguages()||[]).join(";"));

        return <div  {...getNativeProps(vnode.attrs,buttonProperties)} 
        className={classnames(origClassName, "LanguageSelector", {
        })}>
            
            <div className="LanguageSelector_rows">
            { Object.keys(selectorLanguages).map( l => {
            
                let invalid = requiredLanguages[l] > vnode.state.levelMap[l]().key


                return <div className={classnames("LanguageSelector_language", {
                    "LanguageSelector_language--invalid" : invalid
                })}>
                    
                    <strong>{selectorLanguages[l]}</strong>
                    <Dropdown items={levelSelect} bind={vnode.state.levelMap[l]} />
                    { invalid && <p className="LanguageSelector_language_error">
                        One of your chosen contingents has a language requirement of {selectorLevels[requiredLanguages[l]]}.
                    </p>}
                </div>
            }
            )}
            </div>
        </div>
    }
}
