import * as m from 'mithril';
import {endpoint} from 'config';
import Application, {IApplicationInput} from './Models/Application'
import Authentication from './Authentication'

export interface User{
    id : string;
    firstname : string;
    lastname : string;
    studiensemester: number;
    role? : string;
}

class CurrentUser{
   public getLastApplication() : Promise<IApplicationInput>{
        return Authentication.request({
            method : "GET",
            url : endpoint + "/users/current/application",
        }).catch((e) => {
            // 404 is normal if no previous application
            if(e.code !== 404){
                throw e;
            }
        });
    }

  public getUser() : Promise<User>{
        return Authentication.request({
            method : "GET",
            url : endpoint + "/users/current"
        });
    }
}

var currentUser = new CurrentUser();
export default currentUser;
