
import {endpoint} from 'config';
import Authentication from './Authentication'

export type ApplicationMode = "INITIAL"|"OPEN"|"VALIDATION"|"ASSIGNMENT"| "PUBLISH";
export type ApplicationPhase = "FIRST" | "SECOND";
export interface ApplicationState{
    mode :  ApplicationMode;
    phase : ApplicationPhase;
    closeOpenAt : string;
    closeAcceptAt : string;
}


export class ApplicationStateService{
   
   public get() : Promise<ApplicationState>{
         return Authentication.request({
            method : "GET",
            url : endpoint + "/appstate"
        });
   }

   public put(data : ApplicationState)  : Promise<ApplicationState> {
        return Authentication.request({
            method : "PUT",
            url : endpoint + "/appstate",
            data : {
                mode : data.mode,
                phase : data.phase,
                closeOpenAt : data.closeOpenAt,
                closeAcceptAt : data.closeAcceptAt
            }
        })
   }
   
}

var instance = new ApplicationStateService();
export default instance;
