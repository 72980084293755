import * as m from 'mithril';
import stream from 'mithril/stream';

import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import Dialog from 'Components/Dialog/Dialog';
import Button from 'Components/Button/Button';
import TextField from 'Components/TextField/TextField'
import './globalToaster.scss';

interface Attr{

}

interface State{
    promptText : prop<string>;
}


export interface ToastMessage {
    title
}


class GlobalToaster extends Component<State,Attr>{
    

    static SHOW_TOAST_DURATION : number = 5000;
    static EXIT_ANIMATION_DURATION : number = 200;


    private static toasts : ToastMessage[] = [];

    public static toast(text : string){
        GlobalToaster.toasts.push({
                title : text
        });

        window.setTimeout(() => {
            GlobalToaster.toasts.shift();
            m.redraw();
        },GlobalToaster.SHOW_TOAST_DURATION);
        m.redraw();
    }


    
    
    static oninit(this: State, vnode : Vnode<State,Attr>){
        this.promptText = stream("");
    }

    static view(this: State, vnode : Vnode<State,Attr>){
           
                return <div className="GlobalToaster"> {
                    GlobalToaster.toasts.map( (t,i) => <div 
                        style={{bottom : (4 * i + 1) + "em"}}
                        className="GlobalToaster__toast"
                        onbeforeremove={(vnode) => {
                            vnode.dom.classList.add("GlobalToaster__toast--out")
                            return new Promise((resolve) => setTimeout(resolve, GlobalToaster.EXIT_ANIMATION_DURATION));
                        }}
                        >
                        { t.title}
                        </div>)} 
                </div>
    }
}

export default GlobalToaster;
