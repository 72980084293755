import * as m from 'mithril';

import Program from './Models/Program';
import WebService from './Classes/WebService';
import Collection from './Classes/Collection';

export class Programs extends WebService<Collection<Program>,Program>{

    constructor(){
        super( "/programs", Collection,Program);
    }

    mapIdToString(id : number) : string{
       return this.model(id) ? this.model(id).name() : "";
    }
}

var instance = new Programs();
export default instance;
