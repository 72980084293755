import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';


import './textField.scss'

import {getNativeProps,baseElementProperties} from 'office-ui-fabric-react/lib/utilities/properties'



interface Attr{
    bind : prop<string | number>;
    placeholder?:string;
    multiline? : boolean;
    autoFocus? : boolean;
    isDisabled? : boolean;
    isRequired? : boolean;
    description? : string;
    label? : string;
    type? : string;
    step? : number;
}

interface State{
}

class TextField extends Component<State,Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
    }


    static _onChange(this: State, vnode : Vnode<State,Attr>, e : KeyboardEvent){
        m.withAttr("value", vnode.attrs.bind)(e);
        
    }

    static view(this: State, vnode : Vnode<State,Attr>){


        let isInvalid = vnode.attrs.bind.isInvalid !== undefined && (vnode.attrs.bind.isInvalid(vnode.attrs.bind()) !== false);
        let origClassName = (vnode.attrs as any).className || "";
        return <div {...getNativeProps(vnode.attrs,baseElementProperties)} className={classnames(origClassName,'ms-TextField', {
            'is-disabled' : !!vnode.attrs.isDisabled,
            'is-required' : !!vnode.attrs.isRequired,
            'ms-TextField--multiline' : !!vnode.attrs.multiline
        })}>
        { vnode.attrs.label ? 
  <label className='ms-Label'>{vnode.attrs.label}</label>  : ""}
  { !vnode.attrs.multiline ? 
  <input oncreate={(vnode)=>{if(vnode.attrs.autoFocus) window.setTimeout(() => vnode.dom.focus(),1)}} className={classnames('ms-TextField-field',{
      'ms-TextField-invalid' : isInvalid
  })} type={vnode.attrs.type || "text"} step={vnode.attrs.step} autoFocus={!!vnode.attrs.autoFocus} disabled={!!vnode.attrs.isDisabled} oninput={TextField._onChange.bind(this,vnode)}  onchange={TextField._onChange.bind(this,vnode)}  value={vnode.attrs.bind()} placeholder={vnode.attrs.placeholder} />
  : 
  <textarea className={classnames('ms-TextField-field',{
      'ms-TextField-invalid' : isInvalid
  })} oninput={TextField._onChange.bind(this,vnode)} disabled={!!vnode.attrs.isDisabled} onchange={TextField._onChange.bind(this,vnode)}  value={vnode.attrs.bind()} placeholder={vnode.attrs.placeholder} />
  }
  <span>
  { isInvalid && vnode.attrs.bind.isInvalid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20"> { vnode.attrs.bind.isInvalid(vnode.attrs.bind())}</p> : ''}
  { vnode.attrs.description? <span className="ms-TextField-description">{vnode.attrs.description}</span> : ""}
  </span>
</div>
      
    }
}



export default TextField;
