import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';


import "./withSidebar.scss";
import classnames from 'classnames';

import GlobalDialog from 'Utilities/GlobalDialog'
import GlobalToaster from 'Utilities/GlobalToaster';

import Link from 'Utilities/Link';

interface Attr{
    className? : string;
    title : string;
    subTitle? : string;
    hideHeader? : boolean
}

interface State{

}

class GuestLayout extends Component<State,Attr>{
    static view(this: State, vnode : Vnode<State,Attr>){
         return <div className="Layout__Sidebar">
       
      
        <header className="Layout__Sidebar__header">
          <div className="Layout__Sidebar__headerLogo"></div>
          <h1 href="/welcome" oncreate={Link} className="Layout__Sidebar__headerTitle">ARex Application</h1>

          <div className="Layout__Sidebar__footer">
                    <a href="https://www.arc.ed.tum.de/arc/impressum/" target="_blank" rel="noopener">Legal details</a>
            </div>
        </header>

            <div className="Layout__Sidebar__body">
                { !vnode.attrs.hideHeader ?
                <div className="Layout__Imageheader">
                    <h1 className="Layout_Hero_title">{vnode.attrs.title}</h1>
                    <h2 className="Layout_Hero_subtitle">{vnode.attrs.subTitle}</h2>
                </div>
                : ""}

                <article key="article" className={classnames(vnode.attrs.className,"Layout__Sidebar__contentFull")}>
                    {vnode.children}
                </article>
            
                
            </div>
       

          <GlobalToaster />
          <GlobalDialog />
        </div>
    }
}

export default GuestLayout;
