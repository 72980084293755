import * as m from 'mithril';
import stream from 'mithril/stream';

import {endpoint} from 'config';

import {throttle} from 'lodash';

import GlobalDialog from 'Utilities/GlobalDialog';
import GlobalToaster from 'Utilities/GlobalToaster';
declare var WP_DEBUG: boolean;
class AuthenticationService{

    private xhrConfig;
    private currentToken = stream(false);

    private navigateToLogin = throttle((route : string) => {
        if (!m.route.get().startsWith("/login")) {
            window.setTimeout( () =>  m.route.set("/login?return=" + encodeURI(route)), 100);
            GlobalToaster.toast("Please Login First");
        }
    }, 500);

    public constructor(){
        
        let savedToken = window.sessionStorage.getItem("auth_token");
        if(savedToken) {
            this.updateToken(savedToken);
        }

        let urlMatch = window.location.hash.match(/#auth=(.*)/);
        if(urlMatch && urlMatch.length == 2){
            this.updateToken(urlMatch[1]);
            // remove login string from url
            history.replaceState(null, document.title, location.pathname + location.search);
        }

        
      
        if(WP_DEBUG){
            m.request({
                method : "GET",
                url : endpoint + "/jwt/fake"
            }).then( data => this.updateToken(data.token));
        }

    }

    public updateToken(token : string){
        this.currentToken(token);
        window.sessionStorage.setItem("auth_token",token);
    }

    public hasToken() : boolean{
        return !!this.currentToken()
    }

    private getConfig(orig){
        var xhrConfig = (xhr) => {
           orig(xhr);
           if (this.hasToken()) {
            xhr.setRequestHeader("Authorization", "Bearer " + this.currentToken);
           }
        }
        return xhrConfig;
    }


    

    public request( ob) : Promise<any>{
        if(!this.hasToken()){
            this.navigateToLogin(m.route.get());
            return Promise.reject("Please login first");
        }

        var org;
        if(ob.config){
            org = ob.config;
        } else {
            org = function(xhr){};
        }
        
        ob.config = this.getConfig(org);
        return m.request(ob).catch(error => {
            if(error.message == "Credentials are required to access this resource." || error.message == "Referenced StoredUser could not be found"){
                this.currentToken(undefined);
                GlobalDialog.error("Not signed in", "Please sign in, your last action might not have been completed","Ok", true).then(() => m.route.set("/login"))
            } else {
                throw error; // re-throw error otherwise
            }
        });
    }

    

}

var authService = new AuthenticationService();
export default authService;
