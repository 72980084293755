import Collection from './Collection';
import Model from './Model';
import Service from './Service';
import Authentication from 'Services/Authentication';
import {endpoint} from 'config';
import DataLoader from 'dataloader';



class WebService<TC extends Collection<any>, TM extends Model<any>> extends Service<TC,TM>{
    

    private modelBaseUrl;
    private loader = new DataLoader((keys) => {
        return Promise.all(keys.map(key => Authentication.request({
           method : "GET",
           cache : false,
           config : (xhr) => xhr.setRequestHeader("Cache-Control", "no-cache"),
           url : endpoint  + this.modelBaseUrl + "/" + key
       })))
    });

    public constructor(modelBaseUrl : string, collectionType : { new(key:string, service : Service<TC,TM>): TC ;},modelType : { new(id : number|string, service : Service<TC,TM>): TM ;}){
        super(collectionType, modelType);
        this.modelBaseUrl = modelBaseUrl;
    }
    
    public fetchModelData(id : string | number, requireFresh : boolean) : Promise<TM> {
        if(requireFresh){
            this.loader.clear(id);
        }
        return this.loader.load(id);
    }

    public deleteModelData(id : number | string) : Promise<TM>{
        return Authentication.request({
           method : "DELETE",
           url : endpoint  + this.modelBaseUrl + "/" + id
       }).then((item :TM) => {
          this.loader.clear(id)
          return item;
       });
    }


    public fetchCollectionData(key : string, requireFresh : boolean)  : Promise<TM[]> {
        return Authentication.request({
            method : "GET",
            url : endpoint  + (key == "_default" ? this.modelBaseUrl : key) + "/"
        }).then((items : TM[]) => {
            items.forEach(item => this.loader.prime(item.id as any, item as any));
            return items;
        });
    }

    public storeModelData(id : string | number | null, model : TM) : Promise<TM>{
        return Authentication.request({
            method : model.id ? "PUT" : "POST",
            url : endpoint  + this.modelBaseUrl + "/" + (model.id ? model.id : ""),
            data : model.toObject()
        }).then((item :TM) => {
            if(item.id){
                this.loader.clear(item.id)
                this.loader.prime(item.id,item);
            }
            return item;
        });
    }
}

export default WebService;