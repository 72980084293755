import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';
import {getNativeProps,baseElementProperties} from 'office-ui-fabric-react/lib/utilities/properties'
import './dropdown.scss';

export interface Item{
    key : number | string;
    value : string;
}

interface Attr{
    items : Item[]
    bind : prop<Item>;
    isDisabled? : boolean;
    label? : string;
    description? : string;
}

interface State{
    handlerClose : () => void;
    isOpen : boolean;
}

class Dropdown extends Component<State,Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
        this.isOpen = false;
        this.handlerClose = (() => {
            this.isOpen = false;
            document.removeEventListener("click",this.handlerClose);
            m.redraw();
        }).bind(this);
    }


    static _toggleClose(this: State, vnode : Vnode<State,Attr>){
        if(this.isOpen){
            this.isOpen = false;
            document.removeEventListener("click",this.handlerClose);
        } else {
            this.isOpen = true;
            window.setTimeout(() => document.addEventListener("click", this.handlerClose),1);
        }
    }

    static _select(this: State, vnode : Vnode<State,Attr>, item : Item){
        vnode.attrs.bind(item);
    }

    static onunload(this: State, vnode : Vnode<State,Attr>){
        document.removeEventListener("click",this.handlerClose);
    }

    static view(this: State, vnode : Vnode<State,Attr>){

        
        var isInvalid = vnode.attrs.bind.isInvalid !== undefined && (vnode.attrs.bind.isInvalid(vnode.attrs.bind()) !== false);

        const bindVal = vnode.attrs.bind();
        // if bind() val has only the key set, read the matching value from the items array given
        if (bindVal && bindVal.key && (bindVal["value"] == null || bindVal.value == "")) {
            let matches = vnode.attrs.items.filter( e => e.key == bindVal.key);
            if (matches.length == 1) {
                bindVal.value = matches[0].value;
            }
        }

        return <div {...getNativeProps(vnode.attrs,baseElementProperties)} >
        
        { vnode.attrs.label ? <label className="ms-Label">{vnode.attrs.label}</label> : ""}
        <div   className={classnames("ms-Dropdown", {
            "is-open" : this.isOpen,
            "is-disabled" : !!vnode.attrs.isDisabled
        })} onclick={Dropdown._toggleClose.bind(this,vnode)}>
        <span className={classnames("ms-Dropdown-title",{
            "ms-Dropdown--invalid": isInvalid
        })}>{bindVal ? bindVal.value : ""}</span>
        <i className="ms-Dropdown-caretDown ms-Icon ms-Icon--ChevronDown"></i>

        <ul className="ms-Dropdown-items" role="listbox">
        { 
           vnode.attrs.items ? vnode.attrs.items.map( i => <li key={i.key} className={classnames('ms-Dropdown-item',{
            'is-selected' : bindVal && i.key === bindVal.key
        })} onclick={Dropdown._select.bind(this,vnode,i)}>{i.value}</li>) : <li>Loading ..</li>}
        </ul>
        <span>
         {isInvalid && vnode.attrs.bind.isInvalid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20"> { vnode.attrs.bind.isInvalid(bindVal)}</p> : ''}
          { vnode.attrs.description? <span className="ms-TextField-description">{vnode.attrs.description}</span> : ""}
        </span>
        
        </div></div>
    }
}

export default Dropdown;
