
import {SEMESTER,STUDENT_LEVEL} from 'Services/Constants';


import ShortListItem from './Shortlist';
import ContingentService from '../Contingents';
import {UploaderFile, UploadStatus} from 'Components/FileUploader/Common';

import l from 'lang';

import Model from '../Classes/Model';
import Service from '../Classes/Service';

import Stream from 'mithril/stream';

export interface IAppFormInput{


    formApplicationId : string | number | null;
    formPrio1 : string | number | null;
    formPrio2 : string | number | null;
    formPrio3 : string | number | null;
    formPrio4 : string | number | null;
    formPrio5 : string | number | null;

    formPrio1Semester : {key:null | SEMESTER, value:string} | null;
    formPrio2Semester : {key:null | SEMESTER, value:string} | null;
    formPrio3Semester : {key:null | SEMESTER, value:string} | null;
    formPrio4Semester : {key:null | SEMESTER, value:string} | null;
    formPrio5Semester : {key:null | SEMESTER, value:string} | null;

    formSemester : number;
    formStudyLevelAbroad : {key:null |  STUDENT_LEVEL, value : string};

    formEcts : number;
    formGrade : string;
    formGradeFiles : UploaderFile[];
    formLebenslaufFiles : UploaderFile[];
    formLevelLang : boolean;

    formLangProf : string,

    formMentoring : boolean;
    formMentoringFiles : UploaderFile[];
    formMotivationFiles : UploaderFile[];
    
    formAllowSharing : boolean;


}
export class IAppForm{

    formApplicationId : prop<string | number | null>;
    formPrio1 : prop<string | number | null>;
    formPrio2 : prop<string | number | null>;
    formPrio3 : prop<string | number | null>;


    formPrio1Semester : prop<SemesterSelectionBitmask>;
    formPrio2Semester : prop<SemesterSelectionBitmask>;
    formPrio3Semester : prop<SemesterSelectionBitmask>;
    formPrio4Semester : prop<SemesterSelectionBitmask>;
    formPrio5Semester : prop<SemesterSelectionBitmask>;

    formSemester : prop<number>;
    formStudyLevel : prop<{key : null | STUDENT_LEVEL, value : string}>;

    formGrade : prop<string>;
    formGradeFiles : prop<UploaderFile[]>;
    formLebenslaufFiles : prop<UploaderFile[]>;
    formLangProf : prop<string>;
    
    formMotivationFiles : prop<UploaderFile[]>;

    formAllowSharing : prop<boolean>;

}


export enum SemesterSelectionBitmask {
    // bitmask! must be increasing 2^ 
    // manual sync with java backend !
    NONE = 0,
    FIRST_SEMESTER = 1,
    SECOND_SEMESTER = 2,
    FULL_YEAR = 4
}

export function semesterBitmaskContains(bitmask : SemesterSelectionBitmask, value : SemesterSelectionBitmask) : boolean {
    return (bitmask & value) == value
}


export class AppForm extends Model<IAppFormInput> implements IAppForm{
    
    formApplicationId : prop<string | number | null> = Stream();
    
    formPrio1 : prop<string | number | null> = Stream();
    formPrio2 : prop<string | number | null> = Stream();
    formPrio3 : prop<string | number | null> = Stream();
    formPrio4 : prop<string | number | null> = Stream();
    formPrio5 : prop<string | number | null> = Stream();
    
    formPrio1Semester : prop<SemesterSelectionBitmask> = Stream();
    formPrio2Semester : prop<SemesterSelectionBitmask> = Stream();
    formPrio3Semester : prop<SemesterSelectionBitmask> = Stream();
    formPrio4Semester : prop<SemesterSelectionBitmask> = Stream();
    formPrio5Semester : prop<SemesterSelectionBitmask> = Stream();

    formSemester : prop<number> = Stream();
    formStudyLevel : prop<{key : null | STUDENT_LEVEL, value : string}> = Stream();
    
    formGrade : prop<string> = Stream();
    formGradeFiles : prop<UploaderFile[]> = Stream();
    formLebenslaufFiles : prop<UploaderFile[]> = Stream();
    

    formLangProf : prop<string> = Stream();

    formMotivationFiles : prop<UploaderFile[]> = Stream();

    formAllowSharing: prop<boolean> = Stream();
    



    public static setSelectionInBitmask(bind: prop<SemesterSelectionBitmask>, type : SemesterSelectionBitmask, value : boolean) {
        let current_value = bind();
        if(current_value === undefined) {
            current_value = SemesterSelectionBitmask.NONE;
        }
    
        if (value) { // activate bits
            bind(current_value | type)
        } else { // deactivate bits
            bind(current_value &~ type)
        }
    }

    public static getSelectionInBitmask(bind: prop<SemesterSelectionBitmask>, type : SemesterSelectionBitmask) : boolean {
        let current_value = bind();
        if(current_value === undefined) {
            current_value = SemesterSelectionBitmask.NONE;
        }
        return semesterBitmaskContains(current_value, type);
    }

    public static bindSelectionInBitmask(bind: prop<SemesterSelectionBitmask>, type : SemesterSelectionBitmask) : prop<boolean>  {

        const p = function(value : boolean | undefined) {
            if (value === undefined) {
                return AppForm.getSelectionInBitmask(bind, type)
            } else {
                return AppForm.setSelectionInBitmask(bind,type,value)
            }
        }

        p.isInvalid = (v) => {
            if (bind() == 0) {
                return "Choose at least one availability"
            }
            return false;
        }
    
        return (p as unknown as prop<boolean>);

    }


    constructor(id : number | string, service : Service<any,any>){
        super(id,service);

         this.formSemester.isInvalid = (value : string | null) => {
            // check integer
            if(!value || parseInt(value).toString() != value) return "Please enter an integer number of semesters";
            var intValue = parseInt(value);
            if(intValue < 1 || intValue > 20) return "Please enter a valid number of semesters";
            return false;
        }
        

        this.formGrade.isInvalid = (strValue : string | null) => {
            if(strValue){
                var value = parseFloat(strValue);
                
                if(strValue == "" || value.toFixed(1) != strValue) return "Please enter your gradepoint average with a single decimal"
                
                if(value< 1 || value > 5) return "Please enter a valid gradepoint average"
            } else {
                return "Please enter a valid gradepoint average";
            }
            return false;
        };

        this.formMotivationFiles.isInvalid = this.formLebenslaufFiles.isInvalid = this.formGradeFiles.isInvalid = (value : UploaderFile[] | null) => {
            if(!value || value.filter(f => f.status === UploadStatus.Finished).length !== 1) return l("gobal.error.upload_file");
            return false;
        }

         

        this.fix();

    }

    public fetch(requireFresh) : Promise<AppForm> {

        var fixSingleFile = (bind : prop<UploaderFile[]>) => {
            if(bind() === undefined) bind([]);
            if(bind().length > 1) bind([bind()[0]]);
        }
        return super.fetch(requireFresh).then((model : AppForm) => {
            fixSingleFile(model.formLebenslaufFiles);
            fixSingleFile(model.formGradeFiles);
            fixSingleFile(model.formMotivationFiles);

            return model;
        })
    }


}

export default AppForm;
