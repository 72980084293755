import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';


import './msOverlay.scss';

interface Attr{
    dark? : boolean,
    close? : () => void;
}

interface State{

}

class Overlay extends Component<State,Attr>{
    static view(this: State, vnode : Vnode<State,Attr>){
        return <div onclick={vnode.attrs.close} className={classnames("ms-Overlay", {
            "ms-Overlay--dark" : !!vnode.attrs.dark,
            "ms-Overlay--withClose" : !!vnode.attrs.close
        })}>{vnode.children}</div>
    }
}

export default Overlay;
