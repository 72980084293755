import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import {getNativeProps,baseElementProperties} from 'office-ui-fabric-react/lib/utilities/properties'

import './choiceGroup.scss';

interface Item{
    key : number | string | null,
    value : string,
    disabled? : boolean
}

interface Attr{
    label : string;
    isRequired? : boolean;
    items : Item[];
    bind : prop<Item>;
    isDisabled? : boolean;
    isHorizonal? : boolean;
    description? : string;
}

interface State{
    id : number;
    isInvalid : string | false;
}

class ChoiceGroup extends Component<State,Attr>{
    static ChoiceGroupId = 0;
    static oninit(this: State, vnode : Vnode<State,Attr>){
        this.id = ChoiceGroup.ChoiceGroupId++;
        this.isInvalid = false;
        if(vnode.attrs.bind.isInvalid){
            this.isInvalid = vnode.attrs.bind.isInvalid(vnode.attrs.bind());
            vnode.attrs.bind.map( val => {
                if(vnode.attrs.bind.isInvalid) {
                    this.isInvalid = vnode.attrs.bind.isInvalid(val);
                }
            });
        }
    }

    static _select(this: State, vnode : Vnode<State,Attr>, item : Item){
        vnode.attrs.bind(item);
    }

    static view(this: State, vnode : Vnode<State,Attr>){
        
       let origClassName = (vnode.attrs as any).className || "";
        return <div {...getNativeProps(vnode.attrs,baseElementProperties)} id={"ChoiceGroup" + this.id + "-main"} className={classnames("ms-ChoiceFieldGroup",origClassName)} role="radiogroup" aria-labelledby={"ChoiceGroup" + this.id + "-label"}>
      <div className="ms-ChoiceFieldGroup-title">
        <label for={"ChoiceGroup" + this.id + "-main"} className={classnames("ms-Label",{
             "is-required" : !!vnode.attrs.isRequired
        })} id={"ChoiceGroup" + this.id + "-label"}>{vnode.attrs.label}</label>
          {this.isInvalid && vnode.attrs.bind.isInvalid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20"> { vnode.attrs.bind.isInvalid(vnode.attrs.bind())}</p> : ''}
      </div>
      <div className={classnames({
          "ms-ChoiceFieldGroup--horizontal" : !!vnode.attrs.isHorizonal
        })}>
        {vnode.attrs.items.map( i => 
            <div className="ms-ChoiceField" key={i.key || ""}>
                <input id={"ChoiceGroup" + this.id + "-label-" + i.key} className="ms-ChoiceField-input" type="radio" aria-describedby={"ChoiceGroup" + this.id + "-label-" + i.key} />
                <label for={"ChoiceGroup" + this.id + "-label-" + i.key} className={classnames("ms-ChoiceField-field", {
                    "is-checked" : vnode.attrs.bind && vnode.attrs.bind() && vnode.attrs.bind().key === i.key,
                    "is-disabled" : vnode.attrs.isDisabled || !!i.disabled
                })} onclick={(!vnode.attrs.isDisabled && !i.disabled) ? ChoiceGroup._select.bind(this,vnode,i) : () => 0}>
                <span className="ms-Label">
                        {i.value}
                    </span>
                </label>
            </div>
        )}
        </div>
        { vnode.attrs.description? <span className="ms-TextField-description">{vnode.attrs.description}</span> : ""}
    </div>
        
     
        
    }
}

export default ChoiceGroup;
