import Model from '../Classes/Model';
import Service from '../Classes/Service';
import Stream from 'mithril/stream';

export interface IUniversityInput{
    name : string;
    countryId : number;
    highCost : boolean;
    noForce : boolean;
    isRed : boolean;
}
export interface IUniversity{
    name : prop<string>;
    countryId : prop<number>;
    highCost : prop<boolean>;
    noForce : prop<boolean>
    isRed : prop<boolean>
}

export class University extends Model<IUniversityInput> implements IUniversity{
    name : prop<string>  = Stream();
    countryId : prop<number>  = Stream();
    highCost : prop<boolean> = Stream();
    noForce : prop<boolean> = Stream();
    isRed : prop<boolean> = Stream();

    constructor(id : number | string, service : Service<any,any>){
        super(id,service);
        this.fix();
    }
}

export default University;
