import * as m from 'mithril';

import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import {getNativeProps,baseElementProperties} from 'office-ui-fabric-react/lib/utilities/properties'


import Contingent,{IContingentInput} from 'Services/Models/Contingent';

import Checkbox from 'Components/Checkbox/Checkbox';
import Button from 'Components/Button/Button';

import './programitem.scss';


import ShortListService from 'Services/Shortlist'
import ShortList,{IShortlistInput} from 'Services/Models/Shortlist';

import CountryService from 'Services/Countries';
import Country from 'Services/Models/Country';


import UniversityService from 'Services/Universities'
import ProgramService from 'Services/Programs'
import { SEMESTER_RESTRICTION } from 'Services/Constants';
import { selectorLanguages, selectorLevels } from 'Components/LanguageSelector/LanguageSelector';
import { SemesterSelectionBitmask, semesterBitmaskContains } from 'Services/Models/AppForm';

interface Attr{
    item : Contingent
}

interface State{
    _cacheOn : boolean;
}

class ProgramItem extends Component<State,Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
    }

    static renderSemesterAvailability(bitmask : SemesterSelectionBitmask) : JSX.IntrinsicElements["span"]  {
        
        return <span title="Indicates whether you can go the first (1) or second (2) semester, or for a full year (F)">
            {semesterBitmaskContains(bitmask, SemesterSelectionBitmask.FIRST_SEMESTER) ? "1" : ""}
            {semesterBitmaskContains(bitmask, SemesterSelectionBitmask.SECOND_SEMESTER) ? "2" : ""}
            {semesterBitmaskContains(bitmask, SemesterSelectionBitmask.FULL_YEAR) ? "F" : ""}
        </span>
    }

    static levelRestrictionMap = {
        "NONE" : "",
        "BACHELOR" : "Ba.",
        "MASTER" : "Ma."
    }

    static renderLanguageString(language : string) : string[] {

        return language.split(";").map( i => i.split(":")).filter(tup => tup[1] != "0").map( both => {
            return selectorLanguages[both[0]] + ":" + selectorLevels[parseInt(both[1],10)].slice(0,2)
        })

    }

    static _toggle(this: State, vnode : Vnode<State,Attr>, state? : boolean) {

        var prog = vnode.attrs.item.toObject();
        (prog as any).priority = -1;
        
        var slI = ShortListService.model(prog.id);
        if(state === undefined){
            return ShortListService.collection().contains(slI);
        }

        slI.update(prog);
        
        if(state === true){
            var oldLength = ShortListService.collection().all().length;
            slI.priority(oldLength+1);
            
            ShortListService.collection().add(slI,oldLength);
        } else {
            ShortListService.collection().remove(slI);

            ShortListService.collection().all().forEach((e,i) => {
                e.priority(i+1)
            });
            ShortListService.persist();

        }

        ShortListService.persist();
    }
    static view(this: State, vnode : Vnode<State,Attr>){
        let i = vnode.attrs.item;
        let origClassName = (vnode.attrs as any).className || "";

        let program = ProgramService.model(i.programId());
        let university = UniversityService.model(program.universityId());
        
        return <tr {...getNativeProps(vnode.attrs,baseElementProperties)} className={classnames(origClassName,"Browse__ProgramItem")}  key={i._cid}
        onclick={() => {
            var s  = ProgramItem._toggle.apply(this,[vnode,undefined]);
            ProgramItem._toggle.apply(this,[vnode,!s]);
            }}>
                    <td><Checkbox label="" bind={ProgramItem._toggle.bind(this, vnode)} /></td>
                    <td>{ CountryService.mapIdToString(university.countryId())}</td>
                    <td>{ university.name()}</td>
                    <td>{ i.places()}</td>
                    <td>{ i.gpaRequirement() > 0 &&  i.gpaRequirement()}</td>
                    <td>{ ProgramItem.renderSemesterAvailability(i.semesterAvailability())}</td>
                    <td>{ ProgramItem.levelRestrictionMap[i.levelRestriction()]}</td>
                    <td>{ i.languageRequirementList() != "NONE:0" && ProgramItem.renderLanguageString(i.languageRequirementList())}</td>
                    <td>
                        { university.highCost() && <abbr title="High Cost of Living">H</abbr>}
                        { university.isRed() && <abbr className="highlight-red" title="'Red' University">R</abbr>}
                    </td>
                    <td>{i.notes()}</td>
                  </tr> 
    }
}

export default ProgramItem;
