/// <reference path="./references.ts" />

import * as m from 'mithril';
import './Styles/main.scss';
import Welcome from 'Modules/Welcome/Welcome';


import EmailLogin from 'Modules/Login/EmailRegister/EmailRegister';
import EmailToken from 'Modules/Login/EmailToken/EmailToken';
import { endpoint } from 'config';
import { startRoute } from 'Utilities/Link';
import 'Utilities/EasterEgg';

import Scratch from 'Modules/Scratch/Scratch1'




let defaultRoute = "/login";

let import_promise_admin;
let admin_route = (str) => {
    return { 
        onmatch : () => {
            if (import_promise_admin == null) {import_promise_admin = import('./main_admin')}
            return import_promise_admin.then((mod) => mod[str]);
        }
    }
}

let import_promise_apply;
let apply_route = (str) => {
    return { 
        onmatch : () => {
            if (import_promise_apply == null) {import_promise_apply = import('./main_apply')}
            return import_promise_apply.then((mod) => mod[str]);
        }
    }
}

let routes : any = {
    '/login': EmailLogin,
    '/login/:token...': EmailToken,
    '/welcome': Welcome,

    '/browse': apply_route('Browse'),
    '/apply': apply_route('Apply'),
    '/confirm/:key': apply_route('Confirm'),
    '/accept/:key': apply_route('Accept'),
    '/preview/:key': apply_route('Preview'),

    '/admin': admin_route('AdminIndex'),
    '/admin/contingents': admin_route('AdminContingentManagement'),
    '/admin/contingents/:countryId': admin_route('AdminContingentManagement'),
    '/admin/contingents/:countryId/:universityId': admin_route('AdminContingentManagement'),
    '/admin/contingents/:countryId/:universityId/:programId': admin_route('AdminContingentManagement'),
    '/admin/contingents/:countryId/:universityId/:programId/:contingentId': admin_route('AdminContingentManagement'),
    '/admin/appstate': admin_route('AdminAppState'),
    '/admin/review': admin_route('AdminReview'),
    '/admin/verify': admin_route('AdminVerify'),
    '/admin/verify/buddy': admin_route('AdminVerifyBuddy'),
    '/admin/verify/buddy/:key': admin_route('AdminVerifyBuddy'),
    '/admin/verify/buddy/:key/:deep': admin_route('AdminVerifyBuddy'),
    '/admin/verify/motivation': admin_route('AdminVerifyMotivation'),
    '/admin/verify/motivation/:key': admin_route('AdminVerifyMotivation'),
    '/admin/verify/motivation/:key/:deep': admin_route('AdminVerifyMotivation'),
    '/admin/verify/transcript': admin_route('AdminVerifyTranscript'),
    '/admin/verify/transcript/:key': admin_route('AdminVerifyTranscript'),
    '/admin/verify/transcript/:key/:deep': admin_route('AdminVerifyTranscript'),
    '/admin/verify/language': admin_route('AdminVerifyLanguage'),
    '/admin/verify/language/:key': admin_route('AdminVerifyLanguage'),
    '/admin/verify/language/:key/:prio': admin_route('AdminVerifyLanguage'),
    "/scratch" : Scratch
    }




if (!WP_DEBUG) {
    m.route.prefix("");
}

startRoute(document.body, defaultRoute, routes);





// webpack hotreplace patcher
if (WP_DEBUG) {
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    if (!sessionStorage.getItem("seenDebugWarnig")) {
        import('Utilities/GlobalDialog').then((GlobalDialog) =>  {
            GlobalDialog.default.error("Testbetrieb", "Website ist im Entwicklungs- und Testbetrieb. Keine gültige Anmeldung ist möglich.", "Ok");
            sessionStorage.setItem("seenDebugWarnig", "1")
        });
    }
}



interface rootVnodes extends HTMLElement {
    vnodes: any[];
}

interface webpackModule {
    hot: {
        data: any;
        dispose: (data: any) => void;
        accept: () => void;
    };
}

declare var module: webpackModule;
declare var WP_DEBUG: boolean;
if (WP_DEBUG) {
    var stateFinder = (obj, result = {}) => {

        for (var i in obj) {
            if (i == "state" && obj[i]) {
                result[i] = obj[i];
            }

            if (i == "children" && obj[i] && obj[i].forEach) {
                result[i] = obj[i].map((e) => stateFinder(e));
            }

            if (i == "instance" && obj[i]) {
                result[i] = stateFinder(obj[i]);
            }
        }

        return result;

    }

    var stateUpdater = (current, update) => {
        for (var i in update) {
            if (i == "state") {
                current[i] = update[i];
            }

            if (i == "children") {
                update[i].map((e, index) => stateUpdater(current[i][index], update[i][index]));
            }

            if (i == "instance") {
                stateUpdater(current[i], update[i]);
            }

        }


    }




    try {
        if (module && module.hot) {
            module.hot.accept();
            if (module.hot.data && module.hot.data.nodes) {
                stateUpdater((<rootVnodes>document.body).vnodes, module.hot.data.nodes);
            }
            module.hot.dispose((data) => {
                data.nodes = stateFinder((<rootVnodes>document.body).vnodes);
            })
            m.redraw();
        }
    } catch (e) { console.error(e) }
}
