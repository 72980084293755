import * as m from 'mithril';
import Polyglot from 'node-polyglot';


import en from 'Translations/en.json';

var instance = new Polyglot({ phrases : en});
var exporter = instance.t.bind(instance);
    
declare var WP_DEBUG;
var DO_TRANSLATE = WP_DEBUG ?  window.location.href.indexOf("?translator") > -1 : false;
if(WP_DEBUG && DO_TRANSLATE){
    var exporter_old = exporter;
    var translation_helper = ((key,options) => <span className="translatable" onclick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    
    instance.extend({
        [key] : window.prompt("Translation for key " + key + " with options " + JSON.stringify(options), instance.phrases[key])
    })
    window["translator_result"] = instance.phrases;
    }}>{exporter_old(key, options)}</span>);

	var div = document.createElement( "div" );
	div.innerHTML = "<p>x</p><style>.translatable{background:orange;border:1px solid #000}</style>";
	(document.head||document.body).appendChild(div.childNodes[1]);

    exporter = translation_helper;
}



export default exporter;

