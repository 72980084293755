import Service from './Service';
import * as m from 'mithril';
import Stream from 'mithril/stream';

import GlobalDialog from 'Utilities/GlobalDialog';
import GlobalToaster from 'Utilities/GlobalToaster';

export class Model<T extends Object>{

    static idCounter = 0;
    public id : number | string | null;
    public _cid : number;

    private lastServerSync : number;

    private service : Service<any,any>;

    public stream : Stream<Model<T>> = Stream();

    constructor(id : string | number | null, service : Service<any,any> ){
        this.id = id;
        this._cid = Model.idCounter++;
        this.service = service;
    }

    public fix(){
        for (var key in this) {
            if (key != "stream" && this.hasOwnProperty(key) && typeof this[key] === "function" && (this[key] as any).toJSON) {
                (this[key] as Stream<any>).map(() => this.stream(this));
            }
        }
    }

    public isInvalid() : string | false{
         var isInvalid = false;
         for (var key in this) {
            if (key != "stream" && this.hasOwnProperty(key) && typeof this[key] === "function" && (this[key] as any).isInvalid) {
                if((this[key] as any).isInvalid((this[key] as any)() as prop<any>) !== false){
                    isInvalid = true;
                    break;
                }
            }
        }

        return isInvalid ? "Model is invalid" : false;
    }

    delete() : Promise<Model<T>>{
       return this.service.deleteModelData(this.id)
       .then((model : any) => {
           GlobalToaster.toast("Deleted");
           return model;
       })
       .catch( error => {
           this.processError(error);
           throw error;
       });
    }

    save() : Promise<Model<T>>{
        var promise =  this.service.storeModelData(this.id, this).then((updatedData) => {
                    GlobalToaster.toast("Saved Data");
                    return this.update(updatedData);
        })
        promise.catch(e => this.processError(e));
        return promise;
    }

    private processError(e : {code,message,errors}){
            
            // fetch current server version
            this.fetch(true).then(m.redraw);
            
            if(e.code == 409){
                    GlobalDialog.error("Error: Conflict","A different user has changed the data while you were editing. Your changes where not saved! Please try again", "Ok");
                } else if(e.code == 400){
                    GlobalDialog.error("Error: Bad Request","Your save request was denied since your data was invalid.", "Ok");
                } else if(e.code == 404){
                    GlobalDialog.error("Error: Not Found","", "Ok");
                } else if(e.code == 412){
                    GlobalDialog.error("Precondition failed","Your action could not be completed since an precondition failed : " + e.message, "Ok");
                } else if(e.code == 422){
                    // hibernate validation error
                    GlobalDialog.error("Validation Error",
                            m("",[
                            m("p","Your data was not saved. Please correct the errors below:"),
                            m("ul", e.errors.map(e => m("li",e)))
                            
                            ])
                    , "Ok");
                } else {
                    GlobalDialog.error("Error","Generic " + e.code + " Error with following message : " + JSON.stringify(e.message), "Ok");
                }
    }

    fetch(requireFresh : boolean) : Promise<Model<T>>{
        if(!this.id) throw Error("Model id not set");
        return this.service.fetchModelData(this.id, requireFresh).then(this.update.bind(this))
    }

    public update(data : T): Model<T>{
        for (var key in data) {
            if (data.hasOwnProperty(key) && key != "id") {
                if(this[key as any] === undefined){
                    this[key as any] = Stream(data[key]);
                } else {
                    this[key as any](data[key]);
                }
            }
        }
        if(data && data["id"]){
            this.id = data["id"];
        }

        this.lastServerSync = Date.now();
        return this;
    }

    public reload(data) : Model<T>{
        return this;
    }

    public toObject() : T & { id : string | number}{
        var obj : any = {};
        for (var key in this) {
            if (key != "stream" && !key.startsWith("_") && this.hasOwnProperty(key) && typeof this[key] === "function" && (this[key] as any).toJSON) {
                var val = (this[key] as any)();
                obj[key] = val && val.toObject ? val.toObject() : val;
            }  
        }
        obj.id = this.id;
        return obj;
    }
}

export default Model;
