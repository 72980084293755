import * as m from 'mithril';
import Stream from 'mithril/stream';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import './Datepicker.scss';
import TextField from '../TextField/TextField';
interface Attr{
    value : prop<string>;
    label? : string;
    description? : string;
}

interface State{
    date : prop<Date>,
    month: prop<number>,
    year: prop<number>,
    pickerOpen : boolean;
    clickHandler : ((e: Event) => void) | null;
}

const msADay = 86400000;
const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const shortMonthNames = [
    "Jan", "Feb", "Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
]
const dayNames = [ "Sun","Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
class Datepicker extends Component<State,Attr>{


    static oninit(this: State, vnode : Vnode<State,Attr>){

       if(vnode.attrs.value() == undefined || Datepicker.parseDate(vnode.attrs.value()) == null){
           vnode.attrs.value(Datepicker.formatDate(new Date()));
       }

       // normalize date (no time)
       this.date = vnode.attrs.value.map(val => {
           var dateVal = Datepicker.parseDate(val);
           if(!dateVal){
               dateVal = new Date();
           }
           return (new Date(dateVal.getTime() - dateVal.getTime() % msADay))
       }) as prop<Date>;
       
       this.month = this.date.map((d:Date) => d.getMonth()) as prop<number>
       this.year = this.date.map((d:Date) =>d.getFullYear())as prop<number>

       
    }

    static _setToday(this: State, vnode:Vnode<State,Attr>){
        var today = new Date();
        this.date((new Date(today.getFullYear(),today.getMonth(),today.getDate())))             
    }
    static _setMonth(this: State, vnode:Vnode<State,Attr>, to : number){
        this.date(new Date(this.year(), to, 1));
    }
    static _changeMonth(this: State, vnode:Vnode<State,Attr>, by : number){
        this.date(new Date(this.year(), this.month() + by, 1));
    }
    
    static _changeYear(this: State, vnode:Vnode<State,Attr>, by : number){
        this.date(new Date(this.year() + by, this.month(), 1));
    }
    static _setValue(this: State, vnode:Vnode<State,Attr>, date : Date){
        vnode.attrs.value(Datepicker.formatDate(date));
        Datepicker._closePicker.apply(this,[vnode]);
    }

    static formatDate(date : Date) : string{
        if(!date) return "";
        var pad = (number) : string => (number < 10  ? '0' : '') + number;
        return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate())
    }

     static parseDate (str) : Date | null {
            if(!str) return null;
            var parts = str.split("-");
            if(parts.length != 3) return null;

            var dt = new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10)
        );

        if(isNaN(dt.getDate())) return null;
        
        return dt
    } 


    static _isThisParent(this: State, vnode:Vnode<State,Attr>, element : HTMLElement){
        if(vnode.dom == element){
            return true
        } else if (element == document.body){
            return false;
        } else {
            return Datepicker._isThisParent.apply(this,[vnode,element.parentElement]);
        }
    }
    static _openPicker(this: State, vnode:Vnode<State,Attr>){

        if(this.clickHandler != null){
            document.body.removeEventListener("click", this.clickHandler);
            this.clickHandler = null;
        }

        this.clickHandler = (e: Event) => {
            if(!Datepicker._isThisParent.apply(this,[vnode,e.target])){
                Datepicker._closePicker.apply(this, [vnode]);
            }
        };

        this.pickerOpen = true;
        window.setTimeout(() => document.body.addEventListener("click", this.clickHandler as ()=>void),1);


        

    }
     static _closePicker(this: State, vnode:Vnode<State,Attr>){
        if(this.clickHandler != null){
            document.body.removeEventListener("click", this.clickHandler);
            this.clickHandler = null;
        }
        this.pickerOpen = false;
        m.redraw();
    
    }

    static view(this: State, vnode : Vnode<State,Attr>){

        
		var firstDay = new Date(this.year(), this.month(), 1);
		var startDate = new Date(firstDay.getTime() - firstDay.getDay()*msADay);
        
        var selectedDateP = Datepicker.parseDate(vnode.attrs.value());
        var selectedDate = (selectedDateP ? selectedDateP : new Date()).getTime();
        
        var today = new Date();
        today = ((new Date(today.getFullYear(),today.getMonth(),today.getDate())))
        let isInvalid = vnode.attrs.value.isInvalid !== undefined && (vnode.attrs.value.isInvalid(vnode.attrs.value()) !== false);

        return <div>
            <div className="ms-TextField">
                { vnode.attrs.label ? <label className="ms-Label">{vnode.attrs.label}</label> : ""}
                <input pattern="[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])" onfocus={Datepicker._openPicker.bind(this,vnode)} 
                className={classnames("ms-TextField-field", {
                "ms-TextField-invalid" : isInvalid 
            })} type="text" value={vnode.attrs.value()} onkeyup={m.withAttr('value', vnode.attrs.value)}/>
            { isInvalid && vnode.attrs.value.isInvalid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20"> { vnode.attrs.value.isInvalid(vnode.attrs.value())}</p> : ''}
            { vnode.attrs.description ? <span className="ms-TextField-description">{vnode.attrs.description}</span> : ""}
            </div>
            <div className="ms-DatePicker">
        <div className={classnames(
            "ms-DatePicker-picker is-monthPickerVisible",
            {
             "ms-DatePicker-picker--opened" : this.pickerOpen   
            })}>
        <div className="ms-DatePicker-holder">
            <div className="ms-DatePicker-frame">
                <div className="ms-DatePicker-wrap">
                    <div className="ms-DatePicker-dayPicker" >
                        <div className="ms-DatePicker-header">
                            <div >
                                <div className="ms-DatePicker-month">{monthNames[this.month()]}</div>
                                <div className="ms-DatePicker-year">{this.year()}</div>
                            </div>
                        </div>
                        <div className="ms-DatePicker-monthComponents">
                            <div className="ms-DatePicker-navContainer">
                                
                                <span onclick={Datepicker._changeMonth.bind(this,vnode,+1)} className="ms-DatePicker-nextMonth js-nextMonth"><i className="ms-Icon ms-Icon--ChevronRight" ></i></span>
                                <span onclick={Datepicker._changeMonth.bind(this,vnode,-1)} className="ms-DatePicker-prevMonth js-prevMonth"><i className="ms-Icon ms-Icon--ChevronLeft" ></i></span>
                            </div>
                            <div
                                className="ms-DatePicker-headerToggleView js-showMonthPicker"></div>
                        </div>
                        <div className="ms-FocusZone">
                            <table className="ms-DatePicker-table">
                                <thead>
                                    <tr>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Sunday">S</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Monday">M</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Tuesday">T</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Wednesday">W</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Thursday">T</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Friday">F</th>
                                        <th className="ms-DatePicker-weekday" scope="col" title="Saturday">S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {[0,1,2,3,4,5].map( week => <tr>
                                        {[0,1,2,3,4,5,6].map( day => {
                                            var date = new Date(startDate.getTime() + (msADay * day) + (msADay * 7 * week));
                                            var currentDay = date.getTime() == selectedDate;
                                            var isToday = date.getTime() == today.getTime();
                                            return <td onclick={Datepicker._setValue.bind(this,vnode,date)} className={classnames("ms-DatePicker-day",{
                                                "ms-DatePicker-day--outfocus" : date.getMonth() != this.month(),
                                                "ms-DatePicker-day--infocus" : date.getMonth() == this.month(),
                                                "ms-DatePicker-day--today" : isToday,
                                                "ms-DatePicker-day--highlighted" : currentDay
                                                })}>{date.getDate()}</td>
                                            
                                        })}
                                </tr>
                            )}
                        </tbody>
                        </table>
                    </div>
                </div>
                <div className="ms-DatePicker-monthPicker">
                    <div className="ms-DatePicker-header">
                        <div className="ms-DatePicker-yearComponents ms-DatePicker-navContainer">
                            <span className="ms-DatePicker-nextYear js-nextYear"   onclick={Datepicker._changeYear.bind(this,vnode,+1)} ><i className="ms-Icon ms-Icon--ChevronRight" ></i></span>
                            <span className="ms-DatePicker-prevYear js-prevYear"  onclick={Datepicker._changeYear.bind(this,vnode,-1)} ><i className="ms-Icon ms-Icon--ChevronLeft" ></i></span>
                            
                            </div>
                        <div
                            className="ms-DatePicker-currentYear js-showYearPicker">{this.year()}</div>
                    </div>
                    <div className="ms-FocusZone">
                        <div className="ms-DatePicker-optionGrid">
                            { shortMonthNames.map((month,i) => <span  onclick={Datepicker._setMonth.bind(this,vnode,i)}  className="ms-DatePicker-monthOption">{month}</span>)}
                        </div>
                    </div>
                </div><span  onclick={Datepicker._setToday.bind(this,vnode)} className="ms-DatePicker-goToday js-goToday">Go to today</span></div>
        </div>
    </div>
</div>
</div>
</div>

    }
}

export default Datepicker;
