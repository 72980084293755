import Collection from './Collection';
import Model from './Model';

export abstract class Service<TC extends Collection<any>, TM extends Model<any>>{

    public collectionType : { new(key:string, service : Service<TC,TM>): TC ;};
    public modelType : { new(id : number|string | null, service : Service<TC,TM>): TM;};

    public constructor(typeCollection : { new(key:string, service : Service<TC,TM>): TC ;}, typeModel : { new(id : number|string, service : Service<TC,TM>): TM ;}){
        this.collectionType = typeCollection;
        this.modelType = typeModel;
    }

    protected collections : {[key:string] : TC} = {};
    collection(key?: string) : TC{
        if(!key) key = "_default";
        if(!this.collections[key]){
            this.collections[key] = new this.collectionType(key, this);
        }
        return this.collections[key];
    }

    protected models : {[key:string] : TM} = {};
    model(id : string | number | null) : TM {

        if(id){
            if(!this.models[id]){
                this.models[id] = new this.modelType(id,this);
            }
            return this.models[id];
        } else {
            var model = new this.modelType(null, this);
            return model;
        }
    }

    public abstract fetchModelData(id : String | number, requireFresh : boolean) : Promise<any>;
    public abstract fetchCollectionData(key : String, requireFresh : boolean) : Promise<any>;

    public abstract storeModelData(id : String | number | null, model : TM) : Promise<TM>;
    public abstract deleteModelData(id : String | number | null) : Promise<null | TM>;

}

export default Service;
