import Model from './Model';
import Service from './Service';

import * as m from 'mithril';
import stream from 'mithril/stream';

import GlobalDialog from 'Utilities/GlobalDialog';

export class Collection<T extends Model<any>>{

    private service : Service<any,any>;
    private key : string;

    private lastServerSync : number;
    private syncActive : number = 0;

    public constructor(key:string, service : Service<any,any> ){
        this.key = key;
        this.service = service;
    }

    private itemStream = stream();

    async fetch(requireFresh : boolean) : Promise<Collection<T>>{
        
        
         // If we have a fairly recent last sync and do not require freshness do not make a new request
         if(!requireFresh && this.lastServerSync && (Date.now() - this.lastServerSync < 20000) ){
            console.log("We have a fairly recent result and no freshness required: Not fetching new ", Date.now() - this.lastServerSync)
            return this;
         }

        var dataFetch = this.service.fetchCollectionData(this.key,requireFresh).then( collectionData => {
           this.itemStream(collectionData.map( el => {
                var model : T = this.service.model(el.id);
                model.update(el);
                return model;
            }));
            this.lastServerSync = Date.now();
        })

        // If we have a fairly recent result, do not wait for new data to finish but return immediately
        if(this.lastServerSync && (Date.now() - this.lastServerSync < 5000) ){
            console.log("We have a fairly recent result; Not waiting for new", Date.now() - this.lastServerSync)
            return this;
        }

        try{
            await dataFetch;
            console.log("Has waited for request")
        } catch (e){
              // 404 is normal if no previous application
            if(e.code !== 403){
                GlobalDialog.error("Permission denied","You don't have permission to access this resource", "Ok", true);
                m.route.set('/')
                return this;
            }
            GlobalDialog.error("Error","Generic Error with following message : " + JSON.stringify(e), "Ok");
        }

        
        return this;
    }

    all() : T[] {
        return this.itemStream() || [];
    }

    stream() : stream<T[]>{
        return this.itemStream;
    }


    remove( item : T) : number{
        let items  = this.itemStream() as T[]
        let pos = items.findIndex( f => f.id === item.id);
        items.splice(pos,1)
        this.itemStream(items);
        return pos;
    }

    deleteItem(item : T){
        var pos = this.remove(item);
        item.delete().catch(e => {
            this.add(item, pos);
            throw e;
        })
    }

    contains(item :T){
        return this.all().some( e => e.id == item.id);
    }

    add(item :T, index = 0){
        var items : T[]  = this.all();
        items.splice(index,0,item);
        this.itemStream(items);
    }


}

export default Collection;
