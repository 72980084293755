import Model from '../Classes/Model';
import Service from '../Classes/Service';
import Stream from 'mithril/stream';

export interface ICountryInput{
    name : string;
}
export interface ICountry{
    name : prop<string>;
}

export class Country extends Model<ICountryInput> implements ICountry{
    name : prop<string>  = Stream();

    constructor(id : number | string, service : Service<any,any>){
        super(id,service);
        this.fix();
    }
}

export default Country;
