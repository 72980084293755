import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import Layout from 'Modules/Layout/FullWidth';
import Button from 'Components/Button/Button';
import TextField from 'Components/TextField/TextField';

import ApplicationService from 'Services/Applications';
import Application from 'Services/Models/Application';
import ContingentService from 'Services/Contingents';
import Contingent from 'Services/Models/Contingent';
import AuthenticationService from 'Services/Authentication';
import CurrentUserService from 'Services/CurrentUser';

import GlobalToaster from 'Utilities/GlobalToaster';
import GlobalDialog from 'Utilities/GlobalDialog';

import {endpoint} from 'config';

import l from 'lang';

interface Attr{
    token : string;
}

interface State{
}

class EmailLogin extends Component<State,Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
      m.request({
          method : "GET",
          url : endpoint + "/emailusers/email?token=" + vnode.attrs.token
      }).then( data => {
         AuthenticationService.updateToken(data.token);
         GlobalToaster.toast("Logged in");
         m.route.set("/welcome",{},{replace : true});
      }).catch(error => GlobalDialog.error("Could not sign in", "We could not sign you in using this link. Please request a new authentication link", "Retry").then(a => m.route.set("/login",{},{replace : true})));

    }



    static view(this: State, vnode : Vnode<State,Attr>){
        return <Layout title="Login">
            Please wait ...
        </Layout>
    }
}

export default (EmailLogin);
