import Collection from './Collection';
import Model from './Model';
import Service from './Service';
import Authentication from 'Services/Authentication';
import {endpoint} from 'config';

class LocalStorageService<TC extends Collection<any>, TM extends Model<any>> extends Service<TC,TM>{
    

    private modelBaseUrl;
    public constructor(modelBaseUrl : string, collectionType : { new(key:string, service : Service<TC,TM>): TC ;},modelType : { new(id : number|string, service : Service<TC,TM>): TM ;}){
        super(collectionType, modelType);

        this.modelBaseUrl = modelBaseUrl;
    }
    
    public fetchModelData(id : string | number) {
        var url = endpoint  + this.modelBaseUrl + "/" + id;
        return new Promise((resolve,reject) => resolve(JSON.parse(window.sessionStorage.getItem(url) || "{}")));
    }

    public fetchCollectionData(key : string) {
        var url =  endpoint  + (key == "_default" ? this.modelBaseUrl : key) + "/"
        return new Promise((resolve,reject) => resolve(JSON.parse(window.sessionStorage.getItem(url) || "[]")));
    }

    public persist(){
        for (var key in this.collections) {
            if (this.collections.hasOwnProperty(key)) {
                let element = this.collections[key];
                var url =  endpoint  + (key == "_default" ? this.modelBaseUrl : key) + "/"
                window.sessionStorage.setItem(url,JSON.stringify(element.all().map(e => e.toObject())));
            }
        }
        
         for (var key in this.models) {
            if (this.models.hasOwnProperty(key)) {
                let element = this.models[key];
                var url = endpoint  + this.modelBaseUrl + "/" + element.id;
                window.sessionStorage.setItem(url,JSON.stringify(element.toObject()));
            }
        }
    }

    public deleteModelData(id : number | string) : Promise<TM | null>{
        var url = endpoint  + this.modelBaseUrl + "/" + id;
        return new Promise(resolve =>  {
            window.sessionStorage.removeItem(url)
            resolve(null);
        });
    }

    public storeModelData(id : string | number | null, model : TM) : Promise<TM>{
        this.persist();
        return new Promise( r => r(model));
    }
}

export default LocalStorageService;
