import * as m from 'mithril';
import stream from 'mithril/stream';

import {Component,Vnode} from 'Components/Component';
import * as classnames from 'classnames';

import ContingentSelector from './ContingentSelector';
import Layout from 'Modules/Layout/FullWidth';


interface Attr{
}

interface State{
    item : any
}



class Scratch1 extends Component<State,Attr>{


    
    
    

    static oninit(this: State, vnode : Vnode<State,Attr>){
         
        vnode.state.item = (cont) => {
            alert(cont);
        }
         
    }

    static view(this: State, vnode : Vnode<State,Attr>){
        
        return <Layout className="Preview" title="Preview" subTitle="Application">

                <h1>Hello World</h1>

                <ContingentSelector onselect={vnode.state.item} />
        </Layout>
    }
}

export default (Scratch1);
