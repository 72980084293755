import * as m from 'mithril';
import stream from 'mithril/stream';

import { Component, Vnode } from 'Components/Component';
import classnames from 'classnames';

import Dialog from 'Components/Dialog/Dialog';
import Button from 'Components/Button/Button';
import TextField from 'Components/TextField/TextField'


interface Attr {

}

interface State {
}


export interface DialogRequest {
    id,
    title
    description?
    resolve?
    prefill?
    promptText?
    error?
    type: "error" | "confirm" | "prompt"
    buttonTrue,
    buttonFalse
}


class GlobalDialog extends Component<State, Attr>{

    static dialogs: DialogRequest[] = [];
    static dialog_id = 0;

    public static async confirm(title: string, description: string, buttonTrue: string, buttonFalse: string): Promise<Boolean> {
        var promise: Promise<Boolean> = new Promise((resolve, error) => {
            GlobalDialog.dialogs.push({
                id : GlobalDialog.dialog_id++,
                title: title,
                description: description,
                resolve: resolve,
                error: error,
                type: "confirm",
                buttonTrue: buttonTrue,
                buttonFalse: buttonFalse
            });
            m.redraw();
        });
        return promise;
    }

    public static async prompt(title: string, description: string, buttonTrue: string, buttonFalse: string, prefill? : string): Promise<string> {
        var promise: Promise<string> = new Promise((resolve, error) => {
            GlobalDialog.dialogs.push({
                id : GlobalDialog.dialog_id++,
                title: title,
                description: description,
                resolve: resolve,
                error: error,
                promptText : prefill ? stream(prefill) : stream(),
                prefill : prefill,
                type: "prompt",
                buttonTrue: buttonTrue,
                buttonFalse: buttonFalse
            });
            m.redraw();
        });
        return promise;
    }


    public static async error(title: string, description: string, buttonTrue: string, singleByTitle? : boolean): Promise<Boolean> {
        var promise: Promise<Boolean> = new Promise((resolve, error) => {
            if(singleByTitle && GlobalDialog.dialogs.filter(a => a.title === title).length > 0) return;
            GlobalDialog.dialogs.push({
                id : GlobalDialog.dialog_id++,
                title: title,
                description: description,
                resolve: resolve,
                type: "error",
                error: error,
                buttonTrue: buttonTrue,
                buttonFalse: null
            });
            m.redraw();
        });
        return promise;
    }

    static reply(this: State, vnode: Vnode<State, Attr>, result: boolean | string | ((string?) => string), e? : Event) {
        if(e) e.preventDefault();
        var dialog = GlobalDialog.dialogs.length > 0 ? GlobalDialog.dialogs[0] : null;
        if (dialog) {
            GlobalDialog.dialogs.shift();
            if (dialog.resolve) dialog.resolve(typeof result == "function" ? result() : result);
            m.redraw();
        }
    }

    static oninit(this: State, vnode: Vnode<State, Attr>) {
    }

    static view(this: State, vnode: Vnode<State, Attr>) {
        var dialog: DialogRequest | null = GlobalDialog.dialogs.length > 0 ? GlobalDialog.dialogs[0] : null;
        if (!dialog) return "";
        switch (dialog.type) {
            case "prompt":
                let desc = <form onsubmit={GlobalDialog.reply.bind(this, vnode, dialog.promptText)}>
                    <p>{dialog.description}</p>
                    <TextField key={"tf_" + dialog.id} autoFocus bind={dialog.promptText} />
                    {dialog.prefill ? <small onclick={((dialog : DialogRequest) => dialog.promptText(dialog.prefill)).bind(this,dialog)}>(Undo Text)</small> : "" }
                    </form>
                return <Dialog key={dialog.id} isOpen close={GlobalDialog.reply.bind(this, vnode, "")} largeHeader title={dialog.title} description={desc} buttons={[
                        dialog.buttonTrue ? <Button primary onclick={GlobalDialog.reply.bind(this, vnode, dialog.promptText)}>{dialog.buttonTrue}</Button> : "",
                        dialog.buttonFalse ? <Button onclick={GlobalDialog.reply.bind(this, vnode, "")}>{dialog.buttonFalse}</Button> : "",
                    ]} />

            case "error": case "confirm":
                return <Dialog key={dialog.id} darkOverlay={dialog.type == "error"} isOpen close={GlobalDialog.reply.bind(this, vnode, false)} largeHeader title={dialog.title} description={dialog.description} buttons={[
                        dialog.buttonTrue ? <Button primary onclick={GlobalDialog.reply.bind(this, vnode, true)}>{dialog.buttonTrue}</Button> : "",
                        dialog.buttonFalse ? <Button onclick={GlobalDialog.reply.bind(this, vnode, false)}>{dialog.buttonFalse}</Button> : "",
                    ]} />
        }
    }
}

  

export default GlobalDialog;
