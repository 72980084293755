import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import './msDialog.scss';

import Overlay from '../Overlay/Overlay';
import Button from '../Button/Button';
interface Attr{
    title : string,
    description : string

    buttons : any[]

    largeHeader? : boolean
    isOpen? : boolean
    darkOverlay? : boolean,
    close : () => void
}

interface State{

}

class Dialog extends Component<State,Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
    
    }
    static view(this: State, vnode : Vnode<State,Attr>){
    return <div className={classnames("ms-Dialog", { 
            "ms-Dialog--lgHeader" : !!vnode.attrs.largeHeader,
            "is-open" : !!vnode.attrs.isOpen
        })}>
        <Overlay close={vnode.attrs.close} dark={vnode.attrs.darkOverlay}/>
        <div className="ms-Dialog-main">
            <div className="ms-Dialog-header">
                <p className="ms-Dialog-title">{vnode.attrs.title}</p>
            </div>
            <div className="ms-Dialog-inner">
                <div className="ms-Dialog-content">
                    <p className="ms-Dialog-subText">
                        {vnode.attrs.description}
                    </p>   
                </div>
                <div className="ms-Dialog-actions">
                    <div className="ms-Dialog-actionsRight">
                    {
                        vnode.attrs.buttons.map(b => <span className="ms-Dialog-action">
                            {b}
                        </span>)
                    }
                    </div>
                </div>

            </div>
    </div>
    </div>

    }
}

export default Dialog;
