import * as m from 'mithril';
import {Component, Vnode} from 'Components/Component';
import classnames from 'classnames';


import "./button.scss";
import {getNativeProps,buttonProperties} from 'office-ui-fabric-react/lib/utilities/properties'

interface Attr {
    onclick?: () => void;
    primary? : boolean;
    invalid? : boolean;
    disabled? : boolean;
    href? : string; // use with oncreate link
}

interface State {

}

export default class Button extends Component<State, Attr>{
    static oninit(vnode: Vnode<State, Attr>) {

    }

   

    static view(vnode: Vnode<State, Attr>) {

        let origClassName = (vnode.attrs as any).className || "";
        return <button onclick={vnode.attrs.disabled ? () => {} : vnode.attrs.onclick} {...getNativeProps(vnode.attrs,buttonProperties)} disabled={vnode.attrs.disabled}
        className={classnames(origClassName, "ms-Button", {
            "is-disabled" : !!vnode.attrs.disabled,
            "ms-Button--primary": !!vnode.attrs.primary,
            "ms-Button--invalid" : !!vnode.attrs.invalid
        })}>
            <span className="ms-Button-label">{ vnode.children }</span>
        </button>
    }
}
