import * as m from 'mithril';

import Shortlist from './Models/Shortlist';
import LocalStorageService from './Classes/LocalStorageService';
import Collection from './Classes/Collection';


export class ShortList extends LocalStorageService<Collection<Shortlist>,Shortlist>{
    constructor(){
        super("shortlist", Collection,Shortlist);
    }
}

var instance = new ShortList();
export default instance;
