import Model from '../Classes/Model';
import Service from '../Classes/Service';
import Stream from 'mithril/stream';

export interface IProgramInput{
    universityId: string | number;
    name : string;
    periodSemester1 : string;
    periodSemester2 : string;
}
export interface IProgram{
    universityId: prop<string | number>;
    name : prop<string>;
    periodSemester1 : prop<string>;
    periodSemester2 : prop<string>;
}

export class Program extends Model<IProgramInput> implements IProgram{
    universityId: prop<string | number> = Stream();
    name : prop<string>  = Stream();
    periodSemester1 : prop<string> = Stream();
    periodSemester2 : prop<string> = Stream();

    constructor(id : number | string, service : Service<any,any>){
        super(id,service);
        this.fix();
    }
}

export default Program;
