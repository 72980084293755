import * as m from 'mithril';

import Model from './Classes/Model';
import WebService from './Classes/WebService';
import Contingent from './Models/Contingent';
import Collection from './Classes/Collection';





export class Contingents extends WebService<Collection<Contingent>,Contingent>{
    constructor(){
        super("/contingents", Collection,Contingent);   
    }
}

var instance = new Contingents();
export default instance;
