import * as m from 'mithril';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

//import 'office-ui-fabric-react/lib/components/Checkbox/Checkbox.scss'
import './checkbox.scss';


interface Attr{
    label : string;
    bind : prop<boolean>;
    isDisabled? : boolean;
}

interface State{

    id : number;
}

class Checkbox extends Component<State,Attr>{
    static idCounter = 0; 
    static oninit(this: State, vnode : Vnode<State,Attr>){
        this.id = Checkbox.idCounter++;
    
    }


    static _toggle(this: State, vnode : Vnode<State,Attr>){
       if(!vnode.attrs.isDisabled) {
        vnode.attrs.bind(!vnode.attrs.bind())
       }
    }

    static onunload(this: State, vnode : Vnode<State,Attr>){
      
    }

    static view(this: State, vnode : Vnode<State,Attr>){

        let isInvalid = vnode.attrs.bind.isInvalid !== undefined && (vnode.attrs.bind.isInvalid(vnode.attrs.bind()) !== false);

       return <div className="ms-Checkbox" >
       <input id={"checkbox-" + this.id}  name={"checkbox-" + this.id} className="ms-Checkbox-input" type="checkbox" / >
       <label for={"checkbox-" + this.id} className={classnames("ms-Checkbox-label", {
           "is-checked" : vnode.attrs.bind(),
           'is-disabled' : !!vnode.attrs.isDisabled
       })} onclick={Checkbox._toggle.bind(this,vnode)}>
       <span className="ms-Label" >{vnode.attrs.label}</span></label>
        { isInvalid && vnode.attrs.bind.isInvalid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20"> { vnode.attrs.bind.isInvalid(vnode.attrs.bind())}</p> : ''}
       </div>
    }
}

export default Checkbox;
