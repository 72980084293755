import * as m from 'mithril';
import Stream from 'mithril/stream';
import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';


import "./button.scss";
import {getNativeProps,buttonProperties} from 'office-ui-fabric-react/lib/utilities/properties'

interface Attr {
    action: () => Promise<any>;
    primary? : boolean;
    invalid? : boolean;
    disabled? : boolean;
    href? : string; // use with oncreate link
}

interface State {
    isRunning : boolean;
}

export default class LoadingButton extends Component<State, Attr>{
    static oninit(this: State, vnode : Vnode<State,Attr>){
       this.isRunning = false;
    }

    static runAction(this: State, vnode : Vnode<State,Attr>){
        this.isRunning = true;
        vnode.attrs.action().then(() => this.isRunning = false).catch(() => this.isRunning = false);
    }

   

    static view(this: State,vnode: Vnode<State, Attr>) {
     
        let origClassName = (vnode.attrs as any).className || "";
        return <button onclick={(vnode.attrs.disabled || this.isRunning) ? () => {} : LoadingButton.runAction.bind(this,vnode)} {...getNativeProps(vnode.attrs,buttonProperties)} disabled={vnode.attrs.disabled}
        className={classnames(origClassName, "ms-Button", {
            "is-disabled" : !!(vnode.attrs.disabled || this.isRunning),
            "ms-Button--primary": !!vnode.attrs.primary,
            "ms-Button--invalid" : !!vnode.attrs.invalid
        })}>
            <span className="ms-Button-label">{ vnode.children }{ this.isRunning ? <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="LoadingButton__uploadSpinner"><rect x="0" y="0" width="100" height="100" fill="none" className="bk"></rect><g><path d="M50 15A35 35 0 1 0 74.787 25.213" className="LoadingButton__uploadSpinner_path"  stroke-width="12px"></path><path d="M50 0L50 30L66 15L50 0" className="LoadingButton__uploadSpinner_head"></path><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1s" repeatCount="indefinite"></animateTransform></g></svg> : ""}</span>
        </button>
    }
}
