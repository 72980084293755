import Contingent from './Contingent';
import Service from '../Classes/Service';

import Stream from 'mithril/stream';

export interface IShortlistInput{
    priority : number;
}

export interface IShortlist{
    priority : prop<number>;
}

export class ShortListItem extends Contingent implements IShortlist{
    priority : prop<number> = Stream();
    constructor(id : number | string, service : Service<any,any>){
        super(id,service);
        this.fix();
    }
}


export default ShortListItem;
